<template>
  <v-form>
    <v-row dense v-if="setup">
      <v-card-text>TimeSheet Template Details</v-card-text>
      <v-col cols="4">
        <v-text-field
          outlined
          dense
          disabled
          label="Name"
          v-model="setup.Name"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          outlined
          dense
          disabled
          label="Check In Time"
          v-model="setup.CheckInTime"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          outlined
          dense
          disabled
          label="Check Out Time"
          v-model="setup.CheckOutTime"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-text-field
          outlined
          dense
          label="Date"
          v-model="record.Date"
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-menu
          ref="menu"
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="time1"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <div>
              <v-text-field
                v-model="record.ClockIn"
                label="CheckIn Time"
                prepend-inner-icon="mdi-clock-time-four-outline"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </div>
          </template>
          <v-time-picker
            v-if="menu1"
            v-model="record.ClockIn"
            full-width
            @click:minute="$refs.menu.save(time1)"
          ></v-time-picker>
        </v-menu>
      </v-col>
      <v-col cols="4">
        <v-menu
          ref="menu"
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="time2"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              v-model="record.ClockOut"
              label="ClockOut Time"
              prepend-inner-icon="mdi-clock-time-four-outline"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="menu2"
            v-model="record.ClockOut"
            full-width
            @click:minute="$refs.menu.save(time2)"
          ></v-time-picker>
        </v-menu>
      </v-col>
      <v-col cols="4">
        <v-autocomplete
          item-text="name"
          outlined
          dense
          item-value="id"
          v-model="record.UserSign"
          label="User"
          :items="Users"
        ></v-autocomplete>
      </v-col>
      <v-col cols="4">
        <v-text-field
          outlined
          dense
          disabled
          label="Late"
          v-model="record.Late"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          outlined
          dense
          disabled
          label="Early Leaving"
          v-model="record.EarlyLeaving"
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field
          outlined
          dense
          label="Status"
          v-model="record.Status"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-textarea
          v-model="record.Comment"
          label="Comment"
          rows="5"
        ></v-textarea>
      </v-col>
      <v-col cols="12">
        <v-btn color="accent" @click="sendData" :loading="loader">
          <v-icon left>mdi-content-save</v-icon>Save
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>


<script>
export default {
  props: {
    initial: {
      type: Object,
    },
  },
  data: () => ({
    record: {},
    setup: {},
    LogTypes: [
      { text: "Auto", value: 1 },
      { text: "Manual", value: 1 },
    ],
    menu2: false,
    menu1: false,
    time1: "",
    time2: "",
    Users: [],
    loader: false,
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
  },
  methods: {
    init(val) {
      this.record = { ...val };
      this.setup = this.record?.setup;
    },
    setDate(date) {
      this.record.date = date;
    },
    sendData() {
      this.$emit("data", this.record);
    },
    getUsers() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/users`)
        .then((res) => {
          self.Users = res.ResponseData;

          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getUsers();
  },
};
</script>